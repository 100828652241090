import React from 'react';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Form, Icon, Input, Radio, Modal } from 'antd';
import { newCheckinRequest, closeCheckinModal } from '../../actions/checkinActions';
import './checkinformmodal.css';

const RadioGroup = Radio.Group;

class CheckInForm extends React.Component {
  componentDidMount() {
    // To initially disable the submit button
    this.props.form.validateFields();
  }

  render() {
    if (!this.props.modalVisible) return null;

    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const hoursError = isFieldTouched('hours') && getFieldError('hours');
    const tagError = isFieldTouched('tag') && getFieldError('tag');
    const activityError = isFieldTouched('activity') && getFieldError('activity');
    return (
      <Modal
        visible={this.props.modalVisible}
        title="Edit item"
        okText="Save"
        onCancel={() => this.props.closeCheckinModal()}
        onOk={() => {
          this.props.onCreate();
        }}
      >
        <Form layout="inline" className="checkin-form">
          <p>Add expense:</p>
          <div className="checkin-form-section">
            <Form.Item validateStatus={activityError ? 'error' : ''} help={activityError || ''}>
              {getFieldDecorator('activity', {
                rules: [{ required: true, message: 'Please input what you paid for!' }],
                initialValue: this.props.checkin.activity,
              })(
                <Input
                  prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Item"
                />,
              )}
            </Form.Item>
            <Form.Item validateStatus={hoursError ? 'error' : ''} help={hoursError || ''}>
              {getFieldDecorator('hours', {
                rules: [{ required: true, message: 'Please input the amount spent!' }],
                initialValue: this.props.checkin.hours,
              })(
                <Input
                  prefix={<Icon type="credit-card" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Amount"
                />,
              )}
            </Form.Item>
          </div>
          <Form.Item className="tags" validateStatus={tagError ? 'error' : ''} help={tagError || ''}>
            {getFieldDecorator('tag', {
              rules: [{ required: true, message: 'Please tag this check in!' }],
              initialValue: this.props.checkin.tag,
            })(
              <RadioGroup>
                <Radio value="Food">Food</Radio>
                <Radio value="Transpo">Transpo</Radio>
                <Radio value="Pets">Pets</Radio>
                <Radio value="General">General</Radio>
                <Radio value="Overhead">Overhead</Radio>
                <Radio value="Exclude">Exclude</Radio>
                <Radio value="Income">Income</Radio>
              </RadioGroup>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = obstruction({
  workDate: 'checkin.workDate',
  modalVisible: 'editCheckin.visible',
  checkin: 'editCheckin.checkin',
});

const mapDispatchToProps = {
  newCheckinRequest,
  closeCheckinModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'checkin_form_modal' })(CheckInForm));
