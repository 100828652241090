import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGINCODE_REQUEST,
} from '../actions/actionTypes';
import { loginSuccess, logincodeSuccess } from '../actions/authActions';
import { appAlertSuccess, appAlertError } from '../actions/alertActions';
import { logoutService, loginService, logincodeService } from '../services/auth';
import { getOrGenerateClientId, removeAuthTokens } from '../helpers/utils';
import { CLIENT_ID, ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/localStorage';

export function* loginFlow({ code, id }) {
  try {
    const clientId = yield call(getOrGenerateClientId);
    const response = yield call(loginService, { clientId, id, password: code });
    const { authenticationToken, refreshToken } = response.data;
    localStorage.setItem(CLIENT_ID, clientId);
    localStorage.setItem(ACCESS_TOKEN, authenticationToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    yield put(loginSuccess(authenticationToken));
    yield put(appAlertSuccess('Login successful.'));
    yield put(push('/'));
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* logoutFlow() {
  try {
    yield call(logoutService);
    yield put(appAlertSuccess('Bye!'));
  } catch (e) {
    // console.log(e);
  }
  removeAuthTokens();
  yield put(push('/'));
}

export function* sendLoginCodeFlow({ email }) {
  try {
    yield put(appAlertSuccess('Sending...'));
    const response = yield call(logincodeService, email);
    const { id } = response.data;
    yield put(logincodeSuccess(id));
    yield put(appAlertSuccess(`Login code sent to ${email}.`));
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* watchAuthFlow() {
  yield all([
    takeEvery(LOGIN_REQUEST, loginFlow),
    takeEvery(LOGOUT_REQUEST, logoutFlow),
    takeEvery(LOGINCODE_REQUEST, sendLoginCodeFlow),
  ]);
}

const authSagas = [watchAuthFlow()];

export default authSagas;
