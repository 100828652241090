import moment from 'moment';

import {
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  NEW_CHECKIN_SUCCESS,
  DELETE_CHECKIN_SUCCESS,
  EDIT_CHECKIN_SUCCESS,
  SET_WORK_DATE,
  OPEN_CHECKIN_MODAL,
} from '../actions/actionTypes';

const initialState = {
  checkins: [],
  isLoading: false,
  workDate: moment(),
};

function checkinReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHECKINS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CHECKINS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        checkins: action.checkins,
      };
    }

    case NEW_CHECKIN_SUCCESS: {
      return {
        ...state,
        checkins: [action.checkin, ...state.checkins.sort((a, b) => a.workDate > b.workDate)],
      };
    }

    case DELETE_CHECKIN_SUCCESS: {
      return {
        ...state,
        checkins: state.checkins.filter(x => x.id !== action.checkinId),
      };
    }

    case EDIT_CHECKIN_SUCCESS: {
      return {
        ...state,
        checkins: state.checkins.map(x => x.id === action.checkin.id ? action.checkin : x),
      };
    }

    case SET_WORK_DATE: {
      return {
        ...state,
        workDate: action.workDate,
      };
    }

    case OPEN_CHECKIN_MODAL: {
      return {
        ...state,
        workDate: moment(action.checkin.workDate),
      };
    }

    default:
      return state;
  }
}

export default checkinReducer;
