import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Button, Empty, Table, Tag, Icon } from 'antd';
import colors from '../../constants/colors';
import { getCheckinsRequest, deleteCheckinRequest, openCheckinModal, editCheckinRequest, newCheckinRequest, closeCheckinModal } from '../../actions/checkinActions';
import { CheckInFormModal } from '..';
import './checkinslist.css';

const DEFAULT_ITEMS = [{
  'workDate': 1,
  'activity': 'Bahay 2/2',
  'tag': 'Overhead',
}, {
  'workDate': 1,
  'activity': 'TMI 2/2',
  'tag': 'Income',
}, {
  'workDate': 2,
  'activity': 'Linode',
  'tag': 'Overhead',
}, {
  'workDate': 5,
  'activity': 'Rent',
  'tag': 'Overhead',
}, {
  'workDate': 5,
  'activity': 'Electricity',
  'tag': 'Overhead',
}, {
  'workDate': 5,
  'activity': 'Water',
  'tag': 'Overhead',
}, {
  'workDate': 5,
  'activity': 'PRU',
  'tag': 'Overhead',
}, {
  'workDate': 7,
  'activity': 'Spotify',
  'tag': 'Overhead',
}, {
  'workDate': 16,
  'activity': 'Bahay 1/2',
  'tag': 'Overhead',
}, {
  'workDate': 16,
  'activity': 'TMI 1/2',
  'tag': 'Income',
}, {
  'workDate': 19,
  'activity': 'Smart',
  'tag': 'Overhead',
}, {
  'workDate': 20,
  'activity': 'Converge',
  'tag': 'Overhead',
}];

const dateFormat = 'YYYY-MM-DD';

class CheckinsList extends Component {
  componentWillMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.props.getCheckinsRequest();
  }

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        if (this.props.checkin.createdAt) {
          this.props.editCheckinRequest({
            ...values,
            workDate: this.props.workDate.format(dateFormat),
            id: this.props.checkin.id,
          });
        } else {
          this.props.newCheckinRequest({
            ...values,
            workDate: this.props.workDate.format(dateFormat),
          });
        }
        form.resetFields();
        this.props.closeCheckinModal();
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const columns = [
      {
        title: <Icon type="calendar" />,
        dataIndex: 'workDate',
        key: 'workDate',
        render: (text, record) => (
          <span className="work-date">
            {moment(record.workDate).format('D')}
          </span>
        ),
      },
      {
        title: 'Item',
        dataIndex: 'activity',
        key: 'activity',
        render: (text, record) => (
          <Button
            style={{ padding: 0, border: 'none' }}
            type="link"
            onClick={() => this.props.openCheckinModal(record)}
            className="activity-btn"
          >
            {record.activity}
          </Button>
        )
      }, {
        title: 'Amount',
        dataIndex: 'hours',
        key: 'hours',
        render: (text, record) => record.hours ? record.hours.toLocaleString() : '-',
        align: 'right',
      }, {
        title: 'Tag',
        dataIndex: 'tag',
        key: 'tag',
        render: (text, record) => (
          <Tag color={colors[record.tag.toLowerCase()]}>{record.tag}</Tag>
        ),
        align: 'center',
      }, {
        title: <Icon type="delete" />,
        key: 'action',
        render: (text, record) => (
          record.createdAt ? (
            <Button
              style={{ padding: 0, border: 'none', color: 'gray' }}
              type="link"
              onClick={() => this.props.deleteCheckinRequest(record.id, record.activity)}
            >
              <Icon type="delete" />
            </Button>
          ) : null
        ),
        align: 'right',
      },
    ];
    const range = moment.range(moment(this.props.workDate).startOf('month'), moment(this.props.workDate).endOf('month'));
    const monthCheckins = this.props.checkins.filter(x => moment(x.workDate).isBetween(range.start, range.end, null, '[]'));
    DEFAULT_ITEMS.forEach(item => {
      const exists = monthCheckins.filter(_ => _.activity === item.activity && _.tag === item.tag)
      if (!exists.length) {
        monthCheckins.push({
          ...item,
          workDate: moment(range.start).date(item.workDate),
          id: `${item.tag}-${item.activity}`
        })
      }
    })
    const dataSource = [
      ...monthCheckins.filter(x => !['Overhead', 'Income'].includes(x.tag)),
      ...monthCheckins.filter(x => x.tag === 'Overhead'),
      ...monthCheckins.filter(x => x.tag === 'Income'),
    ]
    return (
      <>
        <p>{this.props.modalVisible ? 'visible' : 'hidden'}</p>
        <Table
          className="checkins"
          columns={columns}
          rowKey="id"
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'You have no recorded items.'} /> }}
          pagination={false}
          size="small"
          dataSource={dataSource}
          rowClassName={record => moment().isSame(record.workDate, 'day') ? 'today' : null}
        />
        <CheckInFormModal
          wrappedComponentRef={this.saveFormRef}
          onCreate={this.handleCreate}
        />
      </>
    );
  }
}

CheckinsList.propTypes = {
  checkins: PropTypes.array.isRequired,
  getCheckinsRequest: PropTypes.func.isRequired,
  deleteCheckinRequest: PropTypes.func.isRequired,
  editCheckinRequest: PropTypes.func.isRequired,
  newCheckinRequest: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  openCheckinModal: PropTypes.func.isRequired,
};

const mapStateToProps = obstruction({
  checkins: 'checkin.checkins',
  workDate: 'checkin.workDate',
  isAuthenticated: 'auth.isAuthenticated',
  modalVisible: 'editCheckin.visible',
  checkin: 'editCheckin.checkin',
});

const mapDispatchToProps = {
  getCheckinsRequest,
  deleteCheckinRequest,
  editCheckinRequest,
  newCheckinRequest,
  openCheckinModal,
  closeCheckinModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinsList);
