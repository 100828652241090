import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { LOGOUT_REQUEST } from '../actions/actionTypes';
import auth from './authReducer';
import checkin from './checkinReducer';
import editCheckin from './editCheckinReducer';
import alert from './alertReducer';

const createAppReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  checkin,
  editCheckin,
  alert,
});

const initialState = {
  auth: {
    logincode: {},
  },
  checkin: {
    checkins: [],
  },
  editCheckin: {},
  alert: {},
};

export default (history) => (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    return {
      ...initialState,
      router: state.router,
    };
  }

  return createAppReducer(history)(state, action);
}
