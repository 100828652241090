import React, { Component } from 'react';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { appAlertClear } from '../../actions/alertActions';
import './appalert.css';

class AppAlert extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.appAlertClear();
  }

  render() {
    if (!this.props.message) return false;

    return (
      <div className="alert-wrapper">
        <Alert
          message={this.props.message}
          type={this.props.alertType}
          showIcon
          banner
          closable
          afterClose={this.handleClose}
        />
      </div>
    );
  }
}

const mapStateToProps = obstruction({
  message: 'alert.message',
  alertType: 'alert.alertType',
  error: 'alert.error',
});

const mapDispatchToProps = {
  appAlertClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAlert);
