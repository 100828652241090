import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  NEW_CHECKIN_REQUEST,
  GET_CHECKINS_REQUEST,
  DELETE_CHECKIN_REQUEST,
  EDIT_CHECKIN_REQUEST,
} from '../actions/actionTypes';
import { newCheckinSuccess, getCheckinsSuccess, deleteCheckinSuccess, editCheckinSuccess } from '../actions/checkinActions';
import { appAlertSuccess, appAlertError } from '../actions/alertActions';
import { newCheckinService, getCheckinsService, deleteCheckinService, editCheckinService } from '../services/checkins';

export function* newCheckinFlow({ hours, tag, activity, workDate }) {
  try {
    yield put(appAlertSuccess('Saving...'))
    const response = yield call(newCheckinService, hours, tag, activity, workDate);
    yield put(newCheckinSuccess(response.data));
    yield put(appAlertSuccess(`Item [${activity}] recorded.`))
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* getCheckinsFlow() {
  try {
    const response = yield call(getCheckinsService);
    yield put(getCheckinsSuccess(response.data));
  } catch (error) {
    // TODO: Don't attempt to fetch after logout
    // const { message } = error.response.data;
    // yield put(appAlertError(message, error));
  }
}

export function* deleteCheckinFlow({ checkinId, activity }) {
  try {
    yield put(appAlertSuccess(`Deleting [${activity}]...`));
    yield call(deleteCheckinService, checkinId);
    yield put(deleteCheckinSuccess(checkinId));
    yield put(appAlertSuccess(`Item [${activity}] deleted.`));
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* editCheckinFlow({ checkin }) {
  const {
    id, hours, tag, activity, workDate
  } = checkin;
  try {
    yield put(appAlertSuccess('Updating...'));
    const response = yield call(editCheckinService, id, hours, tag, activity, workDate);
    yield put(editCheckinSuccess(response.data));
    yield put(appAlertSuccess(`Item [${activity}] updated.`))
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* watchCheckinFlow() {
  yield all([
    takeLatest(NEW_CHECKIN_REQUEST, newCheckinFlow),
    takeLatest(GET_CHECKINS_REQUEST, getCheckinsFlow),
    takeLatest(DELETE_CHECKIN_REQUEST, deleteCheckinFlow),
    takeLatest(EDIT_CHECKIN_REQUEST, editCheckinFlow),
  ]);
}

const checkinSagas = [watchCheckinFlow()];

export default checkinSagas;
