import * as React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Divider, Icon, Statistic } from 'antd';
import { connect } from 'react-redux';
import obstruction from 'obstruction';
import colors from '../../constants/colors';
import './expensesgraph.css';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const DataSet = require('@antv/data-set');

class ExpensesGraph extends React.Component {
  render() {
    const { checkins } = this.props;
    const range = moment.range(moment(this.props.workDate).startOf('month'), moment(this.props.workDate).endOf('month'));
    const monthCheckins = checkins.filter(x => moment(x.workDate).isBetween(range.start, range.end, null, '[]'))

    const currentTags = [...new Set(monthCheckins.map(x => x.tag))];
    const tags = ['Food', 'Transpo', 'Pets', 'General', 'Overhead'].filter(tag => currentTags.includes(tag))

    const sourceData = tags.map(tag => ({
      item: tag,
      count: Math.round((monthCheckins.filter(x => x.tag === tag).map(x => x.hours).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    }));

    const dv = new DataSet.View().source(sourceData);
    dv.transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    });
    const data = dv.rows;
    if (!data) return null;
    const food = data.find(x => x.item === "Food")
    const transpo = data.find(x => x.item === "Transpo")
    const pets = data.find(x => x.item === "Pets")
    const overhead = data.find(x => x.item === "Overhead")

    const expenses = Math.round((monthCheckins.filter(x => x.tag !== "Income").map(x => x.hours).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    const income = Math.round((monthCheckins.filter(x => x.tag === "Income").map(x => x.hours).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    const savings = Math.round((income - expenses + Number.EPSILON) * 100) / 100

    const rangeLastMonth = moment.range(moment(this.props.workDate).subtract(1, 'months').startOf('month'), moment(this.props.workDate).subtract(1, 'months').endOf('month'));
    const checkinsLastMonth = checkins.filter(x => moment(x.workDate).isBetween(rangeLastMonth.start, rangeLastMonth.end, null, '[]'))

    const foodLastMonth = checkinsLastMonth.filter(x => x.tag === "Food").map(x => x.hours).reduce((x, y) => x + y, 0)
    const transpoLastMonth = checkinsLastMonth.filter(x => x.tag === "Transpo").map(x => x.hours).reduce((x, y) => x + y, 0)
    const petsLastMonth = checkinsLastMonth.filter(x => x.tag === "Pets").map(x => x.hours).reduce((x, y) => x + y, 0)
    const overheadLastMonth = checkinsLastMonth.filter(x => x.tag === "Overhead").map(x => x.hours).reduce((x, y) => x + y, 0)
    const expensesLastMonth = Math.round((checkinsLastMonth.filter(x => x.tag !== "Income").map(x => x.hours).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    const incomeLastMonth = Math.round((checkinsLastMonth.filter(x => x.tag === "Income").map(x => x.hours).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100
    const savingsLastMonth = Math.round((incomeLastMonth - expensesLastMonth + Number.EPSILON) * 100) / 100

    const foodDiff = Math.round(((food && food.count) || 0) - foodLastMonth + Number.EPSILON) * 100 / 100
    const transpoDiff = Math.round(((transpo && transpo.count) || 0) - transpoLastMonth + Number.EPSILON) * 100 / 100
    const petsDiff = Math.round(((pets && pets.count) || 0) - petsLastMonth + Number.EPSILON) * 100 / 100
    const overheadDiff = Math.round(((overhead && overhead.count) || 0) - overheadLastMonth + Number.EPSILON) * 100 / 100
    const expensesDiff = Math.round(expenses - expensesLastMonth + Number.EPSILON) * 100 / 100
    const incomeDiff = Math.round(income - incomeLastMonth + Number.EPSILON) * 100 / 100
    const savingsDiff = Math.round(savings - savingsLastMonth + Number.EPSILON) * 100 / 100

    return (
      <React.Fragment>
        <Divider />
        <p>{`Expenses for ${range.start.format('MMMM YYYY')}:`}</p>
        <Card bordered={false} className="expenses-graph">
          <div className="bar-segments">
            {data.map(x => (
              <div
                key={x.item}
                className="bar-segment"
                style={{
                  width: `${x.percent * 100}%`,
                  backgroundColor: colors[x.item.toLowerCase()],
                }}
              >
                {`${(x.percent * 100).toFixed(2)}%`}
              </div>
            ))}
          </div>
          <div className="badges">
            {data.map(x => (
              <Badge color={colors[x.item.toLowerCase()]} text={x.item} key={x.item} />
            ))}
          </div>
        </Card>
        <Divider />
        <Card bordered={false} className="statistics-wrapper">
          <div className="statistics">
            <Statistic title="Food" value={food ? food.count : 0} />
            <Statistic title="Transpo" value={transpo ? transpo.count : 0} />
            <Statistic title="Pets" value={pets ? pets.count : 0} />
            <Statistic title="Overhead" value={overhead ? overhead.count : 0} />
          </div>
          <div className="statistics statistics-diff">
            <Statistic valueStyle={{ color: foodDiff < 0 ? colors.blue : colors.red }} prefix={<Icon type={foodDiff > 0 ? "arrow-up" : foodDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(foodDiff)} />
            <Statistic valueStyle={{ color: transpoDiff < 0 ? colors.blue : colors.red }} prefix={<Icon type={transpoDiff > 0 ? "arrow-up" : transpoDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(transpoDiff)} />
            <Statistic valueStyle={{ color: petsDiff < 0 ? colors.blue : colors.red }} prefix={<Icon type={petsDiff > 0 ? "arrow-up" : petsDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(petsDiff)} />
            <Statistic valueStyle={{ color: overheadDiff < 0 ? colors.blue : colors.red }} prefix={<Icon type={overheadDiff > 0 ? "arrow-up" : overheadDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(overheadDiff)} />
          </div>
          <div className="statistics">
            <Statistic title="Expenses" value={expenses} />
            <Statistic title="Income" value={income} />
            <Statistic title="Savings" value={savings} />
          </div>
          <div className="statistics statistics-diff">
            <Statistic valueStyle={{ color: expensesDiff < 0 ? colors.blue : colors.red }} prefix={<Icon type={expensesDiff > 0 ? "arrow-up" : expensesDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(expensesDiff)} />
            <Statistic valueStyle={{ color: incomeDiff < 0 ? colors.red : colors.blue }} prefix={<Icon type={incomeDiff > 0 ? "arrow-up" : incomeDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(incomeDiff)} />
            <Statistic valueStyle={{ color: savingsDiff < 0 ? colors.red : colors.blue }} prefix={<Icon type={savingsDiff > 0 ? "arrow-up" : savingsDiff < 0 ? "arrow-down" : "line"} />} value={Math.abs(savingsDiff)} />
          </div>
        </Card>
        <Divider />
      </React.Fragment>
    );
  }
}

ExpensesGraph.propTypes = {
  checkins: PropTypes.array.isRequired,
  workDate: PropTypes.object.isRequired,
};

const mapStateToProps = obstruction({
  checkins: 'checkin.checkins',
  workDate: 'checkin.workDate',
});

export default connect(mapStateToProps)(ExpensesGraph);
