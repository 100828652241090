export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGINCODE_REQUEST = 'LOGINCODE_REQUEST';
export const LOGINCODE_SUCCESS = 'LOGINCODE_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const NEW_CHECKIN_REQUEST = 'NEW_CHECKIN_REQUEST';
export const NEW_CHECKIN_SUCCESS = 'NEW_CHECKIN_SUCCESS';

export const GET_CHECKINS_REQUEST = 'GET_CHECKINS_REQUEST';
export const GET_CHECKINS_SUCCESS = 'GET_CHECKINS_SUCCESS';

export const DELETE_CHECKIN_REQUEST = 'DELETE_CHECKIN_REQUEST';
export const DELETE_CHECKIN_SUCCESS = 'DELETE_CHECKIN_SUCCESS';

export const OPEN_CHECKIN_MODAL = 'OPEN_CHECKIN_MODAL';
export const CLOSE_CHECKIN_MODAL = 'CLOSE_CHECKIN_MODAL';
export const EDIT_CHECKIN_REQUEST = 'EDIT_CHECKIN_REQUEST';
export const EDIT_CHECKIN_SUCCESS = 'EDIT_CHECKIN_SUCCESS';

export const SET_WORK_DATE = 'SET_WORK_DATE';

export const APP_ALERT = 'APP_ALERT';
export const APP_ALERT_CLEAR = 'APP_ALERT_CLEAR';
