import {
  NEW_CHECKIN_REQUEST,
  NEW_CHECKIN_SUCCESS,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  DELETE_CHECKIN_REQUEST,
  DELETE_CHECKIN_SUCCESS,
  OPEN_CHECKIN_MODAL,
  CLOSE_CHECKIN_MODAL,
  EDIT_CHECKIN_REQUEST,
  EDIT_CHECKIN_SUCCESS,
  SET_WORK_DATE,
} from './actionTypes';

export function newCheckinRequest(data) {
  return { type: NEW_CHECKIN_REQUEST, ...data };
}

export function newCheckinSuccess(checkin) {
  return { type: NEW_CHECKIN_SUCCESS, checkin };
}

export function getCheckinsRequest() {
  return { type: GET_CHECKINS_REQUEST };
}

export function getCheckinsSuccess(checkins) {
  return { type: GET_CHECKINS_SUCCESS, checkins };
}

export function deleteCheckinRequest(checkinId, activity) {
  return { type: DELETE_CHECKIN_REQUEST, checkinId, activity };
}

export function deleteCheckinSuccess(checkinId) {
  return { type: DELETE_CHECKIN_SUCCESS, checkinId };
}

export function setWorkDate(workDate) {
  return { type: SET_WORK_DATE, workDate };
}

export function openCheckinModal(checkin) {
  return { type: OPEN_CHECKIN_MODAL, checkin };
}

export function closeCheckinModal() {
  return { type: CLOSE_CHECKIN_MODAL };
}

export function editCheckinRequest(checkin) {
  return { type: EDIT_CHECKIN_REQUEST, checkin };
}

export function editCheckinSuccess(checkin) {
  return { type: EDIT_CHECKIN_SUCCESS, checkin };
}
