import api from '../helpers/apiClient';

export function loginService(credentials) {
  return api.post('/auth/login', { grantType: 'password', ...credentials });
}

export function logincodeService(email) {
  return api.post('/auth/send-code', { email });
}

export function logoutService() {
  return api.post('/auth/logout');
}
