import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  AppAlert,
  Dashboard,
} from '../components';
import './App.css';

function App() {
  return (
    <div className="App">
      <AppAlert />
      <Switch>
        <Route exact path="/" render={ownProps => <Dashboard {...ownProps} />} />
      </Switch>
    </div>
  );
}

export default App;
