import api from '../helpers/apiClient';

export function newCheckinService(hours, tag, activity, workDate) {
  return api.post('/checkins/', { hours, tag, activity, work_date: workDate });
}

export function getCheckinsService() {
  return api.get('/checkins');
}

export function deleteCheckinService(checkinId) {
  return api.delete(`/checkins/${checkinId}`);
}

export function editCheckinService(checkinId, hours, tag, activity, workDate) {
  return api.post(`/checkins/${checkinId}`, { hours: `${hours}`, tag, activity: activity.trim(), work_date: workDate });
}
