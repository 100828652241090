import React from 'react';
import PropTypes from 'prop-types';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import './greeting.css';

const Greeting = (props) => {
  return <p className="greeting">{`Welcome, ${props.displayName}!`}</p>;
};

Greeting.propTypes = {
  displayName: PropTypes.string.isRequired,
};

const mapStateToProps = obstruction({
  displayName: 'auth.user.user.displayName',
})

export default connect(mapStateToProps)(Greeting);
