import { Chart, Tooltip, Axis, Legend, StackBar } from 'viser-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { connect } from 'react-redux';
import obstruction from 'obstruction';
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const DataSet = require('@antv/data-set');

class DailyHoursGraph extends React.Component {
  render() {
    const { checkins } = this.props;
    const range = moment.range(moment(this.props.workDate).startOf('month'), moment(this.props.workDate).endOf('month'));
    const fields = Array.from(range.by('day')).map(x => x.format('MMM D'));
    const tags = [...new Set(checkins.map(x => x.tag))];
    const sourceData = tags.filter(tag => tag !== 'Income').map(tag => {
      const d = { name: tag };
      for (let i = 0; i < fields.length; i++) {
        let day = fields[i];
        let hours = checkins.filter(x => x.tag === tag && moment(x.workDate).format('MMM D') === day).map(x => x.hours);
        let total = hours.reduce((a, b) => a + b, 0);
        d[day] = total;
      }
      return d;
    });
    const dv = new DataSet.View().source(sourceData);
    dv.transform({
      type: 'fold',
      fields: fields,
      key: 'day',
      value: 'hours',
    });
    const data = dv.rows;

    return (
      <React.Fragment>
        <p style={{ marginTop: 20 }}>{`Distribution of amount per tag per day from ${fields[0]} to ${fields[fields.length - 1]}:`}</p>
        <Card style={{ marginTop: 20 }}>
          <Chart forceFit height={400} data={data}>
            <Tooltip />
            <Axis />
            <Legend />
            <StackBar position="day*hours" color="name" />
          </Chart>
        </Card>
      </React.Fragment>
    );
  }
}

DailyHoursGraph.propTypes = {
  checkins: PropTypes.array.isRequired,
  workDate: PropTypes.object.isRequired,
};

const mapStateToProps = obstruction({
  checkins: 'checkin.checkins',
  workDate: 'checkin.workDate',
});

export default connect(mapStateToProps)(DailyHoursGraph);
