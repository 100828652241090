import {
  OPEN_CHECKIN_MODAL,
  CLOSE_CHECKIN_MODAL,
} from '../actions/actionTypes';

const initialState = {
  visible: false,
  checkin: {},
};

function editCheckinReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CHECKIN_MODAL:
      return {
        ...state,
        visible: true,
        checkin: action.checkin,
      };

    case CLOSE_CHECKIN_MODAL:
      return {
        ...state,
        visible: false,
        checkin: {},
      };

    default:
      return state;
  }
}

export default editCheckinReducer;
